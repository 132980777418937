<template>
  <div class="column">
    <div v-if="!state">
      <form class="form" :class="displayOnTouch ? 'center-on-mobile' : 'left-align'" @submit.prevent="contact">
        <div class="group" @click="setFocus($event)">
          <input ref="name" :class="{ 'jsmbFocus':name }" v-model="name" type="text" required="required" class="has-background-transparent"/>
          <label for="name" :class="isHome?'home':''">Nom & prénom</label>
          <div class="bar"></div>
        </div>
        <div class="group" @click="setFocus($event)">
          <input ref="phone" :class="{ 'jsmbFocus':phone }" v-model="phone" type="number" required="required" class="has-background-transparent"/>
          <label for="phone" :class="isHome?'home':''">Téléphone</label>
          <div class="bar"></div>
        </div>
        <div class="group" @click="setFocus($event)">
          <input ref="email" :class="{ 'jsmbFocus':email }" v-model="email" type="email" required="required" class="has-background-transparent"/>
          <label for="email" :class="isHome?'home':''">Email</label>
          <div class="bar"></div>
        </div>
        <div class="group textareaCustom" @click="setFocus($event)">
          <textarea placeholder="Votre message" rows="7" ref="message" v-model="message" required="required" class="textarea has-background-transparent" :class="isHome?'home':''"></textarea>
          <div class="bar"></div>
        </div>
        <button class="button is-white is-small has-text-text" type="submit">Envoyer
          <b-loading :is-full-page="false" :active.sync="contactLoading"></b-loading>
        </button>
        <Social class="formu" color="is-white" size="is-medium"/>
      </form>
    </div>
    <div v-else>
      <h2 class="has-text-white is-size-3">Merci de nous avoir contactés</h2>
      <br>
      <p>Nous vous répondrons dans les délais les plus brefs</p>
      <br>
      <p><em>L'équipe JeStockeMonBateau</em></p>
    </div>
  </div>
</template>

<script>
import { CONTACT_REQUEST } from '@/store/modules/landing/actions/landing.actions'
import { mapGetters } from 'vuex'
import Social from '@/components/template/ui/Social.vue'

export default {
  name: 'FormContact',
  components: {Social},

  props: {
    isHome: { type: Boolean, default: false }
  },
  data () {
    return {
      name: '',
      phone: '',
      email: '',
      message: '',
      error: [],
      state: false
    }
  },
  methods: {
    setFocus(event) {
      var inputRef = event.target.htmlFor
      if (inputRef !== undefined) {
        this.$refs[inputRef].focus()
        
      }
    },
    // addFocus(){
    //   this.$refs["phone"].classList.add("jsmbFocus")
    //   console.log(this.$refs["phone"])
    // },
    contact () {
      const { name, phone, email, message } = this
      this.$store.dispatch(CONTACT_REQUEST, { name, phone, email, message })
        .then(() => this.contactSucceed())
        .catch(error => this.contactFailed(error))
    },
    contactSucceed () {
      this.state = true
    },
    contactFailed (error) {
      console.log(error)
      this.error = error
    }
  },
  created () {
    this.changeStateFav = this.$props.isFavorite
    if (localStorage.getItem('user-id') !== null) {
      this.isAuth = true
    }
  },
  computed: {
    ...mapGetters(['contactLoading'])
  },
}
</script>

<style scoped lang="css">
  .group.textareaCustom {
    overflow: initial;
    background: transparent;
    border: none;
    border-bottom: solid 1px rgba(255, 255, 255, 0.5);
    height: max-content;
  }
  .textareaCustom textarea {
    margin-top: 1rem;
    border:none;
    padding: 0;
    color:#fff;
    font-size: 1em;
    box-shadow: none;
  }
  .contact-form {
    padding-top: 3rem;
    padding-bottom: 1.5rem;
  }
  /* Title */
  .contact-form .title {
    /* font-size: 5em; */
    word-break: keep-all;
  }
  
  .contact-form .text-size-touch {
    margin: auto;
    text-align: center;
    font-size: 4em;
  }
  .contact-form :not(.text-size-touch) .title div{
    width: 60%;
    display: flex;
    flex-direction: column;
    flex-direction: center;
    align-items: center;
  }
  .contact-title-left-padding{
    padding-left: 6rem;
  }

  /* Form Material */
  .contact-form .column:last-of-type .form{
    display: flex;
    flex-direction: column;
    width:70%;
  }
  .contact-form .form .button, .contact-form .form .social-wrap{
    align-self: center;
  }
  .center-on-mobile {
    margin: auto;
  }
  .group {
    width: 100%;
    height: 80px;
    overflow: hidden;
    position: relative;
  }
  label {
    position: absolute;
    top: 35px;
    color: rgba(255, 255, 255, 0.5);
    cursor: text;
    transition: .25s ease;
  }
  input {
    display: block;
    width: 100%;
    padding-top: 35px;
    border: none;
    border-bottom: solid 1px rgba(255, 255, 255, 0.5);
    border-radius: 0;
    color: white;
    font-size: 1.5em;
    transition: .3s ease;
  }
  input:valid ~ label {
    top: 0;
    color: rgba(255, 255, 255, 0.5);
  }
  input:focus {
    outline: none;
  }
  input:focus ~ label {
    top: 0;
  }
  input:focus ~ .bar:before {
    transform: translateX(0);
  }
  .bar {
    content: '';
    width: 100%;
    height: 2px;
    transition: .3s ease;
    position: relative;
    display: none;
  }
  .bar:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    background: #fff;
    transform: translateX(-100%);
  }
  .button {
    margin-top: 3rem;
    padding: 0rem 3rem;
    height: 2.5rem;
    margin-bottom: 3rem;
  }
  .has-background-transparent {
    background: transparent
  }
  textarea::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  .home {
    color: #000;
  }
  textarea.home::placeholder {
    color: #000;
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  input.jsmbFocus + label {
    top:0 !important
  }
</style>
