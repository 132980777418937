<template>
  <section class="hero is-info is-fullheight">
    <div class="hero-body">
        <div class="container has-text-left">
          <div class="columns">
              <div class="column is-6">
                  <h1 class="title is-size-hero has-text-left has-text-weight-bold is-uppercase">
                      Conta-<br/>ctez<br/>-<br/>nous
                  </h1>
                  <p>Vous avez une question relative à notre site ?</p>
                  <p>Vous souhaitez en savoir plus ?</p>
                  <strong>Envoyez-nous un message</strong>
              </div>
              <div class="column is-6 has-text-left">
                <FormContact />
              </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import FormContact from '@/components/template/ui/FormContact.vue'

export default {
  name: 'Contactez-nous',
  components: {FormContact},

  metaInfo () {
    let siteName = this.$store.state.globalConstant.SITE_NAME,
      baseUrl = this.$store.state.globalConstant.BASE_URL,
      canonical = this.$router.resolve({ name: 'Contact' });

    let title = (siteName+' - Contactez-nous - stockage de bateau chez les particuliers et professionnels - Hivernage Bateau - stockage bateau hors d\'eau'),
      description = (siteName+' est le spécialiste du stockage de bateaux chez les particuliers et professionnels. Vous avez une question relative à JeStockeMonBateau.com ? Vous souhaitez en savoir plus sur comment stocker, hiverner votre bateau ? Ou encore comment proposer un local, entrepot, garage… Envoyez-nous un message.');

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: title,
      link: [
        { rel: 'canonical', href: (baseUrl+canonical.href) },
      ],
      meta: [
        { 
          vmid: 'description', 
          name: 'description', 
          content: description 
        },
        {
          'property': 'og:title',
          'content': title,
          'vmid': 'og:title'
        },
        {
          'property': 'og:description',
          'content': description
        },
        {
          'property': 'og:url',
          'content': (baseUrl+this.$route.fullPath),
          'vmid': 'og:url'
        },
        {
          'property': 'og:image',
          'content': (baseUrl+require('./../../../assets/og-image.png')),
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:secure_url',
          'content': (baseUrl+require('./../../../assets/og-image.png')),
          'vmid': 'og:image:secure_url'
        },
        {
          'property': 'og:image:alt',
          'content': title,
          'vmid': 'og:image:alt'
        },
        {
          'property': 'twitter:title',
          'content': title,
          'vmid': 'twitter:title'
        },
        {
          'property': 'twitter:description',
          'content': description,
          'vmid': 'twitter:description'
        },
        {
          'property': 'twitter:image',
          'content': (baseUrl+require('./../../../assets/tw-card.jpg')),
          'vmid': 'twitter:image'
        },
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .hero {
    background: #fff url('./../../../assets/contact.jpg');
    background-size:cover; 
  }
  .hero-body {
    background: #00000061;
  }
  .is-size-hero {
    font-size: 7rem;
    word-break: keep-all;
  }
  @media screen and (max-width: 446px) {
    .is-size-hero {
      font-size: 5em;
      word-break: keep-all;
    }
  }
</style>
